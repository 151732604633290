import React, { useEffect, useState, useRef } from "react";
import UserWrapper from "./user.style";
import PageTitle from "../../components/common/PageTitle";
import { connect } from "react-redux";
import StaticpageServices from "../../api/StaticpageServices";
import Toaster from "../../components/common/Toaster";
import StaticPageForm from "../../components/staticpage/StaticPageForm";

const StaticPageEdit = (props) => {
  const [editedData, setEditedData] = useState(undefined);
  const hasParam = props.match.params.id;

  const toaster = useRef();

  useEffect(() => {
    StaticpageServices.view(hasParam).then((res) => {
      setEditedData(res.data.data);
    });
  }, []);

  const submitFormHandler = (data, action) => {
    const fileName=localStorage.getItem("files");
    const filePath=`/public/${fileName}`;
    
    data.filename =fileName;
    data.filepath =filePath;

    console.log(data);

    if (action === "add") {
      const params = {
        platform: data.platform,
        page_name_en: data.titleEnglish,
        page_name_fr: data.titleFrench,
        page_content_en: data.contentEn,
        page_content_fr: data.contentFr
      };
      StaticpageServices.add(params)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.history.push("/staticpage");
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
        });
    } else if (action === "edit") {
      const params = {
        title: data.title,
        content: data.content,
      };
      console.log(data)
      StaticpageServices.edit(data.page_id,params)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.history.push("/staticpage");
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.staticpage"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.staticpage",
            },
            {
              name: hasParam ? "action.edit" : "action.add",
            },
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/staticpage")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Page
            </div>
          </div>
          <div className="roe-card-body">
            <StaticPageForm
              onSubmit={submitFormHandler}
              editedData={editedData}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
        <Toaster ref={toaster} />
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(StaticPageEdit);
