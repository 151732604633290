import React, { useState, useEffect } from "react";
import { compose } from "redux";
import enhancer from "./validator";
import { withRouter } from "react-router";
import DropdownTreeSelect from "react-dropdown-tree-select";
import AdminManagementServices from "../../api/AdminManagementServices";

const AdminForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    setFieldValue,
  } = props;

  // const [disabled, setDisabled] = useState(false);
  const [moduleDropdown, setModuleDropdown] = useState(null);

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  useEffect(() => {
    // if (props.editedData.length > 0) {
    //   setDisabled(true);
    // }
    AdminManagementServices.getModule()
      .then((res) => res.data.data)
      .then((res) => {
        const Data = res.map((result) => {
          return {
            label: result.name,
            id: result.module_id,
            checked: false,
          };
        });
        return Data;
      })
      .then((res) => setModuleDropdown(res));
  }, []);

  useEffect(() => {
    if (props.editedData) {
      const selectedModules = props.editedData.modules.map((result) => {
        return result.module_id;
      });
      setFieldValue("modules", selectedModules);
    }
  }, [props.editedData]);

  const getModules = () => {
    const selectedIds = values.modules || [];
    return moduleDropdown
      ? moduleDropdown.map((modules) => {
          if (selectedIds.includes(modules.id)) {
            modules.checked = true;
          } else {
            modules.checked = false;
          }
          return modules;
        })
      : [];
  };

  const moduleOnChange = (currentNode, selectedNodes) => {
    const IdArray = [];
    selectedNodes.forEach((result) => {
      IdArray.push(result.id);
    });
    setFieldValue("modules", IdArray);

    //Create_data
    // if (props.editedData) {
    //   if (currentNode.checked) {
    //     if (values.citys.includes(currentNode.id)) {
    //       values.cityss.forEach((result) => {
    //         if (result.city_id === currentNode.id) {
    //           dataD.push(result);
    //         }
    //       });
    //     } else {
    //       dataD.push({ city_id: currentNode.id });
    //     }

    //     if (dlt_ar.includes(currentNode.id)) {
    //       dlt_ar.forEach((result, i) => {
    //         if (result === currentNode.id) {
    //           dlt_ar.splice(i, 1);
    //         }
    //       });
    //     }
    //   } else {
    //     if (values.citys.includes(currentNode.id)) {
    //       values.cityss.forEach((result, i) => {
    //         if (result.city_id === currentNode.id) {
    //           dataD.splice(i, 1);
    //           dlt_ar.push(result.city_id);
    //         }
    //       });
    //     } else {
    //       // if (dataD.includes(currentNode.id)) {
    //       dataD.forEach((result, i) => {
    //         if (result.city_id === currentNode.id) {
    //           dataD.splice(i, 1);
    //         }
    //       });
    //       // }
    //     }
    //   }
    //   setFieldValue("deletedData", dlt_ar);
    //   setFieldValue("createdData", dataD);
    // }
  };

  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            First Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            placeholder="First Name"
          />
          <Error field="firstName" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">
            Last Name <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            placeholder="Last Name"
          />
          <Error field="lastName" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">
            Email <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Select Modules<span className="asterisk">*</span>
          </label>
          <DropdownTreeSelect
            id="modules"
            data={getModules()}
            onChange={moduleOnChange}
          />
          <Error field="modules" />
        </div>
        <div>
          <button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default compose(
  enhancer,
  withRouter
)(AdminForm);
