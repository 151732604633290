import React from "react";
import CategoryWrapper from "./staticpage.style";
import { connect } from "react-redux";
import StatcPageViewDetails from "../../components/staticpage/StaticPageViewDetails";
import PageTitle from "../../components/common/PageTitle";

const StaticPageView = props => {
  return (
    <CategoryWrapper {...props}>
      <PageTitle title="sidebar.staticpage" className="plr-15" />
      <div className="user-tbl">
        <StatcPageViewDetails {...props} />
      </div>
    </CategoryWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StaticPageView);
