import React, { useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import enhancer from "./validator";
import AdminServices from "../../api/AdminServices";
import Toaster from "../common/Toaster";
import { withRouter } from "react-router-dom";
import AuthActions from "../../redux/auth/actions";
import { ImageResolution } from "../../helper/constant";

const { admin } = AuthActions;

const EditProfileForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    setFieldValue,
    setFieldTouched,
    values,
    action,
   
  } = props;

  const toaster = useRef();
  const [uploadImage, setUploadImage] = useState(null);
  const [imageObj, setImageObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };
  const handleImage = (e, setValue) => {
    if (e.target.files[0]) {
      let fileObj = e.target.files[0];
      setImageObj(fileObj);
      if (fileObj) {
        const img = new Image();

        img.src = window.URL.createObjectURL(fileObj);

        img.onload = function() {
          const width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          setValue("width", width);
          setValue("height", height);
          setFieldTouched("image", true, true);
          if (
            width >= ImageResolution.WIDTH &&
            height >= ImageResolution.HEIGHT
          ) {
            setValue("image", URL.createObjectURL(fileObj));
            setUploadImage(URL.createObjectURL(fileObj));
          }
        };
      } else {
        //No file was input or browser doesn't support client side reading
        // form.submit();
      }
    } else {
      setUploadImage(null);
    }
  };
  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;
    console.log("h")
    if (isValid) {
      onSubmit(values, action);
      const params = {
        name: values.fullname,
        image: imageObj
      };
      const formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }
      setLoading(true)
      AdminServices.update(formData)
        .then(res => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.admin({
              full_name:res.data.data.full_name,
              profile_image: res.data.data.profile_image
            });
            setTimeout(() => {
              setLoading(false)
              props.history.push("/staticPage");
            }, 1000);
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">Full name</label>
          <input
            type="text"
            className="form-control react-form-input"
            id="fullname"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fullname}
            placeholder="Full name"
          />
          <Error field="fullname" />
        </div>

        <div className="form-group">
          <label className="fs-16 medium-text">Email</label>
          <input
            type="email"
            className="form-control react-form-input"
            id="email"
            disabled
            onChange={handleChange}
            onBlur={handleBlur}
            value={props.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>
        <div>
          <div className="form-group">
            <label className="fs-16 medium-text">
              Profile<span className="asterisk">*</span>
            </label>
            <br />
            <div className="file-upload">
              <label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
                Upload image
                <input
                  id="profile_photo"
                  className="file-upload__input"
                  name="file-upload"
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={e => handleImage(e, setFieldValue)}
                  onBlur={handleBlur}
                />
              </label>
            </div>
            <br />
            {
              <img
                src={uploadImage ? uploadImage : values.profile_image}
                alt=""
                className="mtb-15"
                style={{
                  width: "200px",
                  borderRadius: "4%",
                  height: "150px",
                  background: "#404040"
                }}
              />
            }
            <br />
            <Error field="profile_photo" />
          </div>
        </div>
        <div>
          <button
           loading={loading}
           disabled={loading}
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px",position:"relative" }}
            onClick={() => handleSubmit()}
            dataStyle="expand-right"
          >
            Submit
          </button>
        </div>
      </form>
      <Toaster ref={toaster} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    full_name: state.auth.full_name,
    email: state.auth.email,
    profile_image: state.auth.profile_image,
    ...state.themeChanger
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, { admin }),
  enhancer
)(EditProfileForm);
