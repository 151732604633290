import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";

export default class UserServices {
  static getUsers(params) {
    Header.setHeaders();
    return axios
      .post(`${baseUrl}/user/list`, params)
      .then((response) => response.data);
  }

  static statusChange(id, status) {
    Header.setHeaders();
    return axios
      .put(`${baseUrl}/user/update_status/${id}`, { is_active: status })
      .then((response) => response.data);
  }

  static editUserDetails(user_id, userDetails) {
    Header.setHeaders();
    return axios
      .put(`${baseUrl}/user/update/${user_id}`, userDetails)
      .then((response) => response.data);
  }

  static deleteUser(user_id) {
    Header.setHeaders();
    return axios
      .put(`${baseUrl}/user/delete/${user_id}`)
      .then((response) => response.data);
  }

  static addDeliveryAddress(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/user/add_delivery_address`, params);
  }

  static updateDeliveryAddress({ address_id, ...params }) {
    Header.setHeaders();
    return axios.put(
      `${baseUrl}/user/update_delivery_address/${address_id}`,
      params
    );
  }

  static deleteDeliveryAddress(address_id) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/user/delete_delivery_address/${address_id}`);
  }

  static dropDown() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/user/dropdown`);
  }

  static countryCode(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/user/country_code/list`, params);
  }

  static users() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/user/dropdown`).then((users) => users.data);
  }

  static approveStatus(id, params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/user/approve_status/${id}`, params);
  }

  static exportList(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/user/export_list`, params);
  }
}
