import React from "react";

const StaticPageViewDetails = (props) => {
  const pageDetails = props.location.state;
  console.log(
    "🚀 ~ file: StaticPageViewDetails.jsx ~ line 5 ~ pageDetails",
    pageDetails
  );

  return (
    <div className="roe-card-style mtb-15 pa-30">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        style={{ color: "rgb(98, 0, 234)" }}
        onClick={() => props.history.push("/staticpage")}
      >
        <i className="fas fa-step-backward"></i> Back
      </div>
      <div className="text-center">
        <h2>Static Page Details</h2>
      </div>
      <div className="pt-4">
        <div style={{ fontWeight: "bolder", textAlign: "left" }}>Title</div>
        <div>{pageDetails.title}</div>
      </div>
      <div className="pt-4">
        <div style={{ fontWeight: "bolder", textAlign: "left" }}>Type</div>
        <div>{pageDetails.type}</div>
      </div>
      <div className="pt-4">
        <div>
          <div style={{ fontWeight: "bolder", textAlign: "left" }}>Content</div>
          <div
            className="text-left"
            dangerouslySetInnerHTML={{ __html: pageDetails.content }}
          />
        </div>
      </div>
    </div>
  );
};

export default StaticPageViewDetails;
