const themeConfig = {
  sidebar: "themedefault", // You can put theme1, theme2, theme3, theme4, theme5, theme6, theme7, theme8 as per your need
  topbar: "themedefault", // You can put theme1, theme2, theme3.....up to...theme7 as per your need
  footer: "themedefault", // You can put theme1, theme2, theme3.....up to...theme7 as per your need
  layout: "themedefault", // You can put theme1, theme2, theme3.....up to...theme6
  theme: "themedefault" // Theme is fix you can not change
};

const language = "english"; // You can put chinese, spanish, french, italian as per your need

export { themeConfig, language };
