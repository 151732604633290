import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";

export default class AdminServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/admin/static-content/list`, params);
  }

  static update(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/content/update_status`, params);
  }

  static delete(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/content/delete`, params);
  }

  static dropdown(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/category/dropdown`, params);
  }

  static add(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/content/add`, params);
  }

  static edit(id,params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/admin/static-content/edit/${id}`, params);
  }

  static view(id) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/admin/view-static-content/${id}`);
  }
}
