import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";

export default class AdminManagementServices {
  static list(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/sub_admin/list`, params);
  }

  static add(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/sub_admin/add`, params);
  }

  static delete(id) {
    Header.setHeaders();
    return axios.delete(`${baseUrl}/sub_admin/delete/${id}`);
  }

  static dropdown(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/sub_admin/dropdown`, params);
  }

  static edit(params, id) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/sub_admin/update/${id}`, params);
  }

  static getModule() {
    Header.setHeaders();
    return axios.get(`${baseUrl}/sub_admin/get_module`);
  }
}
