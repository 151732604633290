import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import reducers from "../redux/reducers";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth/reducer";
// import createEncryptor from "redux-persist-transform-encrypt";

// const encryptor = createEncryptor({
//   secretKey: "my-super-secret-key",
//   onError: function(error) {
//     // Handle the error.
//   }
// });

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  // transforms: [encryptor]
};

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_NAME,
});
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
    auth: persistReducer(persistConfig, authReducer),
  }),
  composeEnhancer(applyMiddleware(...middlewares))
);
const persistor = persistStore(store);
store.subscribe(() => {
  sessionStorage.setItem("token", store.getState().auth.token);
  sessionStorage.setItem("isLogin", store.getState().auth.isLogin);
  // localStorage.setItem("name", store.getState().auth.name);
  // localStorage.setItem("email", store.getState().auth.email);
});

export { store, history, persistor };
