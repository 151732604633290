import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().trim().required("This field is required"),
    content: Yup.string().trim().required("This field is required"),
    
  }),
  mapPropsToValues: props => ({
    page_id: props.editedData
      ? props && props.editedData && props.editedData.page_id
      : "",
    user_type: props.editedData
      ? props && props.editedData && props.editedData.user_type
      : "",
    content: props.editedData
      ? props && props.editedData && props.editedData.content
      : "",
    title: props.editedData
      ? props && props.editedData && props.editedData.title
      : "",
    filename: props.editedData
      ? props && props.editedData && props.editedData.filename
      : "",
    filepath: props.editedData
      ? props && props.editedData && props.editedData.filepath
      : "",
 
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
