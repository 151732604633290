import Layout from "../layouts/DashboardLayout";
import { Login, Register, ForgotPassword } from "views/pages/index";
import Otp from "../views/pages/authentication/Otp";

const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/", component: Layout },
];

export default indexRoutes;
