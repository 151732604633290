import React from "react";
import Button from "../../components/button/Button";

const DeleteModal = props => {
  return (
    <div className="text-center">
      <div>Are you sure you want to delete this?</div>
      <div>
        <Button
          className="c-btn c-danger mr-10 mt-10"
          style={{ maxWidth: "125px" }}
          type="button"
          onClick={props.deleteHandler}
        >
          delete
        </Button>
        <Button
          className="c-btn c-info mr-10 mt-10"
          style={{ maxWidth: "125px" }}
          onClick={props.toggle}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteModal;
