import React from "react";
import FooterWrapper from "./footer.style";

const Footer = props => {
  return (
    <FooterWrapper {...props}>
      <div className="footerBack">
        <ul className="list-inline ma-0">
          <li className="list-inline-item">
            © 2021 Practworks , All rights reserved.
          </li>
          {/* <li className="list-inline-item pull-right">
                        Handcrafted With{" "}
                        <i
                            style={{ color: "#e91e63" }}
                            className="fas fa-heart"
                        />
                    </li> */}
        </ul>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
