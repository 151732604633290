import axios from "axios";
import { baseUrl } from "../helper/constant";
import Header from "./Header";

export default class AdminServices {
  static login(params) {
    return axios.post(`${baseUrl}/admin/login`, params);
  }

  static update(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/admin/edit-profile`, params);
  }

  static forgetPassword(email) {
    Header.setHeaders();
    return axios.get(`${baseUrl}/admin/forgot-password/${email}`);
  }

  static otp(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/admin/verify-otp`, params);
  }

  static resetpassword(params) {
    Header.setHeaders();
    return axios.post(`${baseUrl}/admin/reset-password`, params);
  }

  static changepassword(params) {
    Header.setHeaders();
    return axios.put(`${baseUrl}/admin/change-password`, params);
  }

  static logout() {
    Header.setHeaders();
    return axios.delete(`${baseUrl}/admin/logout`);
  }
}
