import React, { useRef } from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import Toaster from "../../components/common/Toaster";
import AdminForm from "../../components/admin/AdminForm";
import AdminManagementServices from "../../api/AdminManagementServices";

const AdminEdit = (props) => {
  const hasParam = props.match.params.id;
  const toaster = useRef();

  const submitFormHandler = (data, action) => {
    if (action === "add") {
      const params = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        admin_modules: JSON.stringify(data.modules),
      };

      AdminManagementServices.add(params)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.history.push("/admin");
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
        });
    } else if (action === "edit") {
      const params = {
        create_module: [
          { module_id: "5", user_module_mapping_id: "9" },
          { module_id: "6", user_module_mapping_id: "10" },
          { module_id: "11" },
        ],
        delete_module: [5],
      };
      AdminManagementServices.edit(params, hasParam)
        .then((res) => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            props.history.push("/category");
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          if (toaster.current) toaster.current.error(err.response);
        });
    }
  };

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle
          title="sidebar.admin"
          className="plr-15"
          breadCrumb={[
            {
              name: "sidebar.admin",
            },
            {
              name: hasParam ? "action.edit" : "action.add",
            },
          ]}
        />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/admin")}
        >
          <i className="fas fa-step-backward" /> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text">
              <span className="hash"># </span> {hasParam ? "Edit" : "Add"} Admin
            </div>
          </div>
          <div className="roe-card-body">
            <AdminForm
              onSubmit={submitFormHandler}
              editedData={props && props.location.state}
              action={hasParam ? "edit" : "add"}
            />
          </div>
        </div>
        <Toaster ref={toaster} />
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(
  mapStateToProps,
  null
)(AdminEdit);
