import React, { useRef } from "react";
import { loginBack, ForgotIcon } from "helper/constant";
import AdminServices from "../../../api/AdminServices";
import Toaster from "../../../components/common/Toaster";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import enhancer from "./enhancer/ResetPasswordValidator";

const ChangePassword = props => {
  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const {
    errors,
    touched,
    submitCount,
    isValid,
    handleSubmit,
    values,
    handleChange,
    handleBlur
  } = props;

  const toaster = useRef();
  // const [password, setPassword] = useState();

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  function submitHandler(e) {
    e.preventDefault();
    if (isValid) {
      const params = {
        new_password: values.newPassword,
        email: props && props.email,
        otp:props && props.otp,
        otp_token:props&&props.otp_token
      };
      AdminServices.resetpassword(params)
        .then(res => {
          if (res.data.status) {
            if (toaster.current) toaster.current.success(res.data.message);
            setTimeout(() => {
              props.history.push("./login");
            }, 500);
          } else {
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch(err => {
          if (toaster.current) toaster.current.error(err.response);
        });
      handleSubmit();
    }
  }

  return (
    <div>
      <div className="container-fluid" style={loginContainer}>
        <div className="form-container">
          <div className="login-icon">
            <img src={ForgotIcon} alt="icon" height="100px" />
          </div>
          <div className="login-title">Change Password</div>

          <div className="text-center form-info-text plr-24 mt-16">
            Enter Your new password
          </div>

          <form className="pa-24" onSubmit={submitHandler}>
            <div className="form-group">
              <input
                id="newPassword"
                type="password"
                onPaste={e => e.preventDefault()}
                className="form-control react-form-input"
                aria-describedby="emailHelp"
                placeholder="enter password"
                // value={values.newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <Error field="newPassword" />
            </div>
            <div className="form-group">
              <input
                id="confirmPassword"
                type="password"
                onPaste={e => e.preventDefault()}
                // value={values.confirmPassword}
                className="form-control react-form-input"
                aria-describedby="emailHelp"
                placeholder="confirm password"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Error field="confirmPassword" />
            </div>
            <button type="submit" className="btn form-button">
              Reset Password
            </button>
          </form>
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(withRouter, enhancer)(ChangePassword);
