import React from "react";
import PageWrapper from "./user.style";
import PageTitle from "../../components/common/PageTitle";
import { connect } from "react-redux";
import StaticPageTable from "../../components/staticpage/StaticPageTable";

const StaticPage = props => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.staticpage" className="plr-15" />
      <div className="user-tbl">
        <StaticPageTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = state => {
  console.log(state)
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(StaticPage);
