import Demo from "views/Demo";
 
import Editprofile from "../views/Editprofile/Editprofile";
 
import StaticPage from "../views/StaticPageManagement/StaticPage";
import StaticPageEdit from "../views/StaticPageManagement/StaticPageEdit";
 
import ChangePassword from "../views/ChangePassword/ChangePassword";
 
 
 
import StaticPageView from "../views/StaticPageManagement/StaticPageView";
 
 
import Admin from "../views/Admin/Admin";
import AdminEdit from "../views/Admin/AdminEdit";
 
 

const dashboardRoutes = [
  { path: "/demo", component: Demo },
  { path: "/admin", component: Admin },
  { path: "/admin/add", component: AdminEdit },
  { path: "/admin/edit/:id", component: AdminEdit },
   
  { path: "/editprofile", component: Editprofile },
  { path: "/changepassword", component: ChangePassword },
  
  { path: "/staticpage", component: StaticPage },
  { path: "/staticpage/add", component: StaticPageEdit },
  { path: "/staticpage/edit/:id", component: StaticPageEdit },
  { path: "/staticpage/view/:id", component: StaticPageView },

];

export default dashboardRoutes;
