import React from "react";
import { Modal, ModalBody } from "reactstrap";

const CommonModel = ({ modal, toggle, className, children, size, style }) => {
  return (
    <div>
      <div>
        <Modal
          centered
          isOpen={modal}
          toggle={toggle}
          className={className}
          size={size}
          style={style}
        >
          <ModalBody>{children}</ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default CommonModel;
