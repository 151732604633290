export const sidebarData = [
  {
    name: "sidebar.staticpage",
    iconClass: "fa fa-clone",
    routepath: "/staticpage",
  },
  // {
  //   name: "sidebar.dashboard",
  //   iconClass: "fas fa-chalkboard",
  //   routepath: "/dashboard",
  // },
  // {
  //   name: "Admin Management",
  //   iconClass: "far fa-user",
  //   routepath: "/admin",
  // },
  // {
  //   name: "sidebar.users",
  //   iconClass: "fa fa-user-circle",
  //   routepath: "/users",
  // },
  // {
  //   name: "sidebar.category",
  //   iconClass: "fa fa-folder-open",
  //   routepath: "/category",
  // },
 
  // {
  //   name: "sidebar.event",
  //   iconClass: "far fa-calendar-alt",
  //   routepath: "/event",
  // },
  // {
  //   name: "sidebar.business",
  //   iconClass: "fa fa-briefcase",
  //   routepath: "/business",
  // },
  // {
  //   name: "sidebar.coupon",
  //   iconClass: "fas fa-tags",
  //   routepath: "/coupon",
  // },
  // {
  //   name: "sidebar.product",
  //   iconClass: "fab fa-product-hunt",
  //   routepath: "/product",
  // },
  // {
  //   name: "sidebar.badge",
  //   iconClass: "fa fa-star",
  //   routepath: "/badge",
  // },
  // {
  //   name: "sidebar.location",
  //   iconClass: "fa fa-location-arrow",
  //   child: [
  //     {
  //       listname: "sidebar.city",
  //       routepath: "/city",
  //       shortname: "C",
  //     },
  //     { listname: "sidebar.state", routepath: "/state", shortname: "SM" },
  //     { listname: "sidebar.country", routepath: "/country", shortname: "CM" },
  //   ],
  // },
  // {
  //   name: "sidebar.promotion",
  //   iconClass: "fa fa-rss",
  //   routepath: "/promotion",
  // },
  // {
  //   name: "sidebar.deal",
  //   iconClass: "fa fa-bullhorn",
  //   routepath: "/deal",
  // },
  // {
  //   name: "sidebar.subscription_plan",
  //   iconClass: "fa fa-check-square",
  //   routepath: "/subscription",
  // },
  // {
  //   name: "Order Management",
  //   iconClass: "fa fa-shopping-cart",
  //   child: [
  //     { listname: "sidebar.myorder", routepath: "/myorder", shortname: "MO" },
  //     { listname: "sidebar.order", routepath: "/order", shortname: "O" },
  //   ],
  // },
  // {
  //   name: "sidebar.payment",
  //   iconClass: "fa fa-credit-card",
  //   routepath: "/payment",
  // },
  // {
  //   name: "sidebar.withdrawal",
  //   iconClass: "fa fa-reply",
  //   routepath: "/withdrawal",
  // },
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/
