import authAction from "./actions";

const initState = {
  isLogin: false,
  accessToken: null,
  firstName: null,
  lastName: null,
  email: null,
  key: null,
  profile_image: null,
  modules: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authAction.LOGIN:
      console.log(action)
      return {
        ...state,
        isLogin: action.isLogin,
        token: action.token,
        full_name: action.full_name,
        email: action.email,
        id: action.id,
        profile_image: action.profile_image
      };
    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: null,
      };
    case authAction.ADMIN:
      return {
        ...state,
        full_name: action.full_name,
        profile_image: action.profile_image,
      };
    default:
      return state;
  }
}
