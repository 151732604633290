import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    fullname: Yup.string().required("This field is required"),
   
  }),
  mapPropsToValues: props => ({
    fullname: props.full_name,
    email: props.email,
    profile_image: props.profile_image
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
