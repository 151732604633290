import React, { useRef, useState } from "react";
import { loginBack, iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import AdminServices from "../../../api/AdminServices";
import Toaster from "../../../components/common/Toaster";
import Button from "../../../components/button/Button";

const { login } = AuthActions;

const Login = (props) => {
  const toaster = useRef();
  const [loading, setloading] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault();
    let { values, handleSubmit } = props;

    if (values.email !== "" && values.password !== "") {
      const params = {
        email: values.email,
        password: values.password,
      };
      setloading(true);
      AdminServices.login(params)
        .then((res) => {
          console.log("🚀 ~ file: Login.jsx ~ line 29 ~ .then ~ res", res)
          if (res.data.status) {
            props.login({
              token: res.data.data.token,
              full_name: res.data.data.full_name,
              email: res.data.data.email,
              id: res.data.data.admin_id,
              profile_image: res.data.data.profile_image,
            });
            setloading(false);

            props.history.push("/staticpage");
          } else {
            setloading(false);
            if (toaster.current) toaster.current.error(res.data.message);
          }
        })
        .catch((err) => {
          setloading(false);
          if (toaster.current) toaster.current.error(err.response);
        });
      //   const data = {
      //     token: "DEMOJWTTOKEN"
      //   };
      // using this method you can store token in redux

      //   props.history.push("/dashboard");
    }
    handleSubmit();
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
  } = props;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={iconDemo} alt="icon" height="100px" />
        </div>
        <div className="login-title">Sign in to your account</div>
        <form className="pa-24" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            <Error field="email" />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control react-form-input"
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
            />
            <Error field="password" />
          </div>

          <Button
            type="submit"
            loading={loading}
            className="btn form-button"
            dataStyle="expand-right"
            style={{ position: "relative" }}
          >
            Login
          </Button>
          <div
            className="text-center link-label"
            onClick={() => props.history.push("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(Login);
