import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import StaticPageServices from "../../api/StaticpageServices";
import Loader from "../common/Loader";
// import CommonModel from "../common/CommonModel";
// import DeleteModal from "../common/DeleteModal";
import Moment from "react-moment";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";

const StaticPageTable = (props) => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("created_at");
  const [sortBy, setSortBy] = useState("DESC");
 
  const toaster = useRef();

  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  

  const columns = [
    {
      Header: "Title",
      accessor: "title",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      
    },
    {
      Header: "Type",
      accessor: "user_type",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      
    },
    {
      Header: "Date",
      accessor: "created_at",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
     
      Cell: (props) => (
        <div>
          <Moment format="MMM-DD-YYYY">{props.original.created_at}</Moment>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
     
      Cell: (props) => (
        <div className="react-action-class">
          <Button
            className="c-btn c-primary mr-10"
            style={{minWidth:"42px",borderRadius:"50%"}}
            onClick={() => formAction("view", props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-info  " />
            </div>
          </Button>
          <Button
            className="c-btn c-success mr-10"
            style={{minWidth:"42px",borderRadius:"50%"}}
            onClick={() => formAction("edit", props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-edit  " />
            </div>
          </Button>
          {/* <Button
            className="c-btn c-danger"
            onClick={() => deleteClick(props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-trash mr-6" /> Delete
            </div>
          </Button> */}
        </div>
      ), // Custom cell components!
    },
  ];

  const onChangePageGetData = (state) => {
    if (state.page !== activePage) {
      // alert("on page change you have to call api and get data");
    }
  };

  
  const formAction = (action, data = null) => {
    // Here you can view the data and make forward action for edit data
    if (action === "add") {
      props.history.push("/staticpage/add");
    } else if (action === "edit") {
      props.history.push(`/staticpage/edit/${data.page_id}`);
    } else if (action === "view") {
      props.history.push(`/staticpage/view/${data.page_id}`, { ...data });
    }
  };

 
  function getList(
    field = "title",
    sortBy = "DESC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
    };
    setLoader(true);
    StaticPageServices.list(params)
      .then((res) => {
        if (res.data.status) {
          settblData(res.data.data.rows);
          setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(res.data.message);
        }
      })
      .catch((err) => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />
            <div className="mr-10">
              <input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              {/* <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-plus mr-10" />
                Add Content
              </Button> */}
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          {/* <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              view ? (
                <DeleteModal deleteHandler={deleteHandler} toggle={toggle} />
              ) : (
                ""
              )
            } */}
          
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(StaticPageTable);
