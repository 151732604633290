import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table";
import Button from "components/button/Button";
import Pagination from "components/common/Pagination";
import "react-table/react-table.css";
import { withRouter } from "react-router";
import AdminManagementServices from "../../api/AdminManagementServices";
import Loader from "../common/Loader";
import CommonModel from "../common/CommonModel";
import DeleteModal from "../common/DeleteModal";
import Moment from "react-moment";
import Toaster from "../common/Toaster";
import { TABLE } from "../../helper/constant";
import UserServices from "../../api/UserServices";

const AdminTable = (props) => {
  const [tblData, settblData] = useState();
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [field, setField] = useState("createdAt");
  const [sortBy, setSortBy] = useState("DESC");
  const [modal, setModal] = useState(undefined);
  const [id, setId] = useState(undefined);
  const [view, setView] = useState(false);

  const {
    sidebarTheme: { activeColor, textColor },
  } = props;

  const toaster = useRef();
  useEffect(() => {
    getList(field, sortBy, searchText, activePage + 1);
    setPages(10);
  }, []);

  const columns = [
    // {
    //   Header: "Image",
    //   accessor: "profile_image",
    //   className: "text-center",
    //   sortable: false,
    //   filterable: false,
    //   headerClassName: "react-table-header-class",
    //   Cell: (props) => (
    //     <div>
    //       <img
    //         src={props.original.profile_image}
    //         alt="error"
    //         style={{ height: "30px", width: "30px", borderRadius: "50%" }}
    //       />
    //     </div>
    //   ),
    // },
    {
      Header: "First Name",
      accessor: "first_name",
      className: "text-center",
      filterable: false,
      width: 150,
      headerClassName: "react-table-header-class",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      className: "text-center",
      filterable: false,
      width: 150,
      headerClassName: "react-table-header-class",
    },
    {
      Header: "Email",
      accessor: "email",
      className: "text-center",
      filterable: false,
      width: 260,
      headerClassName: "react-table-header-class",
    },
    {
      Header: "Date",
      accessor: "createdAt",
      className: "text-center",
      filterable: false,
      headerClassName: "react-table-header-class",
      width: 160,
      Cell: (props) => (
        <div>
          <Moment format="MMM-DD-YYYY">{props.original.createdAt}</Moment>
        </div>
      ),
    },

    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 180,
      Cell: (props) => (
        <div className="react-action-class">
          {props.original.is_active ? (
            <div id={`active-${props.original.user_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-info mr-10"
                onClick={() => activeInactiveStatusHandler(props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-off mr-6" /> Active
                </div>
              </Button>
            </div>
          ) : (
            <div id={`deactive-${props.original.user_id}`}>
              <Button
                style={{ minWidth: "125px" }}
                className="c-btn c-warning mr-10"
                onClick={() => activeInactiveStatusHandler(props.original)}
              >
                <div className="fs-14 medium-text">
                  <i className="fas fa-toggle-on mr-6" /> InActive
                </div>
              </Button>
            </div>
          )}
        </div>
      ), // Custom cell components!
    },
    {
      Header: "Action",
      accessor: "action",
      className: "text-center",
      headerClassName: "react-table-header-class",
      sortable: false,
      filterable: false,
      width: 280,
      Cell: (props) => (
        <div className="react-action-class">
          {/* <Button
            className="c-btn c-primary mr-10"
            onClick={() => formAction("view", props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-info mr-6" /> view
            </div>
          </Button> */}
          <Button
            className="c-btn c-success mr-10"
            onClick={() => formAction("edit", props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-edit mr-6" /> Edit
            </div>
          </Button>
          <Button
            className="c-btn c-danger"
            onClick={() => deleteClick(props.original)}
          >
            <div className="fs-14 medium-text">
              <i className="fas fa-trash mr-6" /> Delete
            </div>
          </Button>
        </div>
      ), // Custom cell components!
    },
  ];

  const onChangePageGetData = (state) => {
    if (state.page !== activePage) {
    }
  };

  const deleteClick = (data) => {
    setId(data.user_id);
    setView(true);
    setModal(true);
  };

  const formAction = (action, data = null) => {
    if (action === "add") {
      props.history.push("/admin/add");
    } else if (action === "edit") {
      props.history.push(`/admin/edit/${data.user_id}`, { ...data });
    } else if (action === "view") {
      props.history.push(`/admin/view/${data.user_id}`, { ...data });
    }
  };

  const activeInactiveStatusHandler = (user) => {
    setLoader(true);
    UserServices.statusChange(user.user_id, !user.is_active)
      .then((response) => {
        if (response.status) {
          if (toaster.current)
            toaster.current.success(
              !user.is_active ? " Admin Active" : "Admin InActive"
            );
          getList(field, sortBy, searchText, activePage + 1);
          setLoader(false);
        }
      })
      .catch((error) => {
        if (toaster.current) toaster.current.error(error);
      });
  };

  function getList(
    field = "title_en",
    sortBy = "ASC",
    search = "",
    page = "1"
  ) {
    const params = {
      sort_by: field,
      order: sortBy,
      search: search,
      page: page,
    };
    setLoader(true);
    AdminManagementServices.list(params)
      .then((res) => {
        if (res.data.status) {
          settblData(res.data.data.rows);
          setPages(Math.ceil(res.data.data.total_records / TABLE.LIMIT));
          setLoader(false);
        } else {
          if (toaster.current) toaster.current.error(res.data.message);
        }
      })
      .catch((err) => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  function deleteHandler() {
    setLoader(true);
    AdminManagementServices.delete(id)
      .then((res) => {
        if (res.data.status) {
          if (toaster.current) toaster.current.success(res.data.message);
          setLoader(false);
          setModal(false);
          setTimeout(() => {
            getList(field, sortBy, searchText, activePage + 1);
          }, 1000);
        } else {
          if (toaster.current) toaster.current.error(res.data.message);
        }
      })
      .catch((err) => {
        if (toaster.current) toaster.current.error(err.response);
      });
  }

  function toggle() {
    setModal(false);
  }

  return (
    <div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header">
            <div className="flex-1 fs-16 demi-bold-text" />
            <div className="mr-10">
              <input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  const search = e.target.value;
                  setLoader(true);
                  setActivePage(0);
                  setTimeout(() => {
                    getList(field, sortBy, search, 1);
                  }, 500);
                }}
                type="text"
                placeholder="Search..."
                className="fs-14 medium-text plr-10 form-control react-form-input"
              />
            </div>
            <div>
              <Button
                className="c-btn ma-5"
                onClick={() => formAction("add")}
                style={{
                  backgroundColor: activeColor,
                  color: textColor,
                }}
              >
                <i className="fas fa-plus mr-10" />
                Add Admin
              </Button>
            </div>
          </div>
          <div className="roe-card-body">
            <ReactTable
              style={{
                border: "none",
                boxShadow: "none",
              }}
              data={tblData}
              columns={columns}
              defaultPageSize={10}
              filterable
              minRows={2}
              manual
              defaultFilterMethod={(filter, row) => {
                const id = filter.pivotId || filter.id;

                return row[id] !== undefined
                  ? String(row[id].toLowerCase()).includes(
                      filter.value.toLowerCase()
                    )
                  : true;
              }}
              className="-striped -highlight custom-react-table-theme-class"
              pages={pages}
              page={activePage}
              PaginationComponent={Pagination}
              loading={loader}
              LoadingComponent={Loader}
              onFetchData={onChangePageGetData}
              onPageChange={(pageIndex) => {
                setActivePage(pageIndex);
                getList(field, sortBy, searchText, pageIndex + 1);
              }}
              onSortedChange={(sortProperties) => {
                const sort = sortProperties[0].desc ? "DESC" : "ASC";
                setSortBy(sort);
                setActivePage(0);
                setField(sortProperties[0].id);
                getList(sortProperties[0].id, sort, searchText, 1);
                // alert("sort handler", sortProperties);
                // const orderByDirection = sortProperties[0].desc ? 1 : 0;

                // updateMapStateInfoOrderByColumn(sortProperties[0].id, orderByDirection);
              }}
            />
          </div>
          <CommonModel
            modal={modal}
            toggle={toggle}
            children={
              view ? (
                <DeleteModal deleteHandler={deleteHandler} toggle={toggle} />
              ) : (
                ""
              )
            }
          />
        </div>
      </div>
      <Toaster ref={toaster} />
    </div>
  );
};

export default withRouter(AdminTable);
