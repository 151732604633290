import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .email()
      .required("Email not valid"),
    modules: Yup.string().required("This field is required"),
  }),
  mapPropsToValues: (props) => ({
    firstName: props.editedData ? props.editedData.first_name : "",
    lastName: props.editedData ? props.editedData.last_name : "",
    email: props.editedData ? props.editedData.email : "",
  }),
  handleSubmit: (values) => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
