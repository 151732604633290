import React from "react";
import { compose } from "redux";
import enhancer from "./validator";
import "./StaticPageForm.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from "../button/Button";
import { Input } from "reactstrap";
// const API_URL = "https://77em4-8080.sse.codesandbox.io";
//const API_URL = "https://api.practworks.com"; 
const API_URL = "https://api.ec2-3-108-0-123.ap-south-1.compute.amazonaws.com"; 
// const API_URL = "https://localhost:3001"; 
// process.env.REACT_APP_BASE_URL
const UPLOAD_ENDPOINT = "upload_files";

function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }  
  
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((fl) => {
            var body = new FormData();            
            console.log("filename", fl)
            body.append("username", "Groucho");    
            
            const obj = {
              lastModified: fl.lastModified,
              name: fl.name,
              size: fl.size,
              type: fl.type,
              webkitRelativePath: fl.webkitRelativePath,
          }

          localStorage.setItem("files",JSON.stringify(obj))
            async function getUser(){
              var files=JSON.parse(await localStorage.getItem("files"))
              console.log("ss",files);
             }
             getUser();
        

            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3001");
          
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
              mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                async function getUser(){
                  var files=JSON.parse(await localStorage.getItem("files"))

                 }
            
                resolve({
                  default: `${API_URL}/${res.filename}`
        });
        
				      })
              .catch((err) => {
                console.log('err',err)
                reject(err);
              });
          });
        });
      }
    };
  }
  

const config = {
	//plugins: [ Essentials, Bold, Italic, Paragraph ],
	extraPlugins: [uploadPlugin],
	toolbar: {
		// extraPlugins: [ MyCustomUploadAdapterPlugin ],       
		items: [
		  'heading',
		  '|',
		  'fontSize',
		  'fontFamily',
		  '|',
		  'bold',
		  'italic',
		  'underline',
		  'strikethrough',
		  'highlight',
		  '|',
		  'alignment',
		  '|',
		  'numberedList',
		  'bulletedList',
		  '|',
		  'indent',
		  'outdent',
		  '|',
		  'todoList',
		  'link',
		  'blockQuote',
		  "mediaEmbed",
		  'imageUpload',
		  'insertTable',      
		  '|',
		  'undo',
		  'redo'
		]
	  },
	  image: {
		toolbar: [
		  'imageStyle:full',
		  'imageStyle:side',
		  'imageTextAlternative'
		]
	  },
	  table: {
		contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
	  },
	  language: 'en'
  };

  
const StaticPageForm = (props) => {
  const {
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
  } = props;

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const handleSubmit = () => {
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }
    handleSubmit();
  };

  return (
    <div>
      <form>
        <div className="form-group">
          <label className="fs-16 medium-text">
            User type<span className="asterisk">*</span>
          </label>
          <Input
            type="select"
            id="user_type"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.user_type}
            placeholder="Select Platform"
            disabled={true}
          >
            <option value="-1">Select user type</option>
            <option value="student">Student</option>
            <option value="evaluator">Evaluator</option>
          </Input>
          <Error field="user_type" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Page Title <span className="asterisk">*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            placeholder="Add Content"
          />
          <Error field="title" />
        </div>
         
        <div className="form-group">
          <label className="fs-16 medium-text">
            Content <span className="asterisk">*</span>
          </label>
          <CKEditor
          editor={ ClassicEditor }
            id="content"
            onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}            
            config={config}
            data={values.content}
            // UploadAdapter={FileRepository}
            onRead={editor => {}}
            onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setFieldValue("content", data);
                        console.log( { event, editor, data } );
                        // console.log( {  editor } );
                    } }
            onBlur={(event) => {
              setFieldTouched("content", true, true);
            }}
          />
          <Error field="content" />
        </div>
        

        <div>
          <Button
            type="button"
            className="c-btn c-info form-button fs-16 demi-bold-text"
            style={{ maxWidth: "125px" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};



export default compose(enhancer)(StaticPageForm);

