const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADMIN: "ADMIN",

  login: (data) => {
  console.log("🚀 ~ file: actions.js ~ line 7 ~ data", data)
    return {
      type: authActions.LOGIN,
      isLogin: true,
      token: data.token,
      full_name: data.full_name,
      email: data.email,
      id: data.id,
      profile_image: data.profile_image,
    };
  },

  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
    };
  },

  admin: (data) => {
    return {
     
      type: authActions.ADMIN,
      full_name: data.full_name,
      profile_image: data.profile_image,
    };
  },
};

export default authActions;
