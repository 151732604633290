import React from "react";
import enhancer from "./validator";
import { compose } from "redux";
import Button from "components/button/Button";

const adminChangePasswordForm = props => {
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    onSubmit,
    values,
    action,
    loading
  } = props;

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    let { values, isValid, handleSubmit } = props;

    if (isValid) {
      onSubmit(values, action);
    }

    handleSubmit();
  };

  return (
    <div>
      <form
        onSubmit={e => {
          handleSubmit(e);
        }}
      >
        <div className="form-group">
          <label className="fs-16 medium-text">
            Current Password<span className="asterisk">*</span>
          </label>
          <input
            type="password"
            onPaste={e => {
              e.preventDefault();
            }}
            className="form-control react-form-input"
            id="currentPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.currentPassword}
            placeholder="Current Password"
          />
          <Error field="currentPassword" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            New Password<span className="asterisk">*</span>
          </label>
          <input
            type="password"
            onPaste={e => {
              e.preventDefault();
            }}
            className="form-control react-form-input"
            id="newPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
            placeholder="New Password"
          />
          <Error field="newPassword" />
        </div>
        <div className="form-group">
          <label className="fs-16 medium-text">
            Confirm Password<span className="asterisk">*</span>
          </label>
          <input
            type="password"
            onPaste={e => {
              e.preventDefault();
            }}
            className="form-control react-form-input"
            id="confirmPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            placeholder="Confirm Password"
          />
          <Error field="confirmPassword" />
        </div>
        <div>
          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            className="c-btn c-info form-button fs-16 demi-bold-text mr-15"
            style={{ maxWidth: "125px" }}
            dataStyle="expand-right"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default compose(enhancer)(adminChangePasswordForm);
